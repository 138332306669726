<template>
  <div class="contact-us" :class="{ mobile: isPhone }">
    <Hero heading="Contact Us">
      <template slot="image">
        <img
          v-if="isPhone"
          src="@/assets/images/Contact-Us/ContactUsMobileHero@2x.png"
          alt="Charger screen with 'Help' options displayed including: How to start a charging session, Connector availability, Vehicle charging compatability, and Become a Member"
        />
        <img
          v-else
          src="@/assets/images/Contact-Us/ContactUsHero@2x.png"
          alt="Charger screen with 'Help' options displayed including: How to start a charging session, Connector availability, Vehicle charging compatability, and Become a Member"
        />
      </template>
    </Hero>
    <Card
      class="contact-card"
      headlineLevel="h2"
      heading="Get help now"
      isReverse
      headlineDisplayClass="headline3"
      bgColor
    >
      <template #image>
        <picture>
          <source media="(min-width:750px)" srcset="@/assets/images/Contact-Us/ContactUsHelp@2x.png" />
          <img
            loading="lazy"
            src="@/assets/images/Contact-Us/ContactUsMobileHelp@2x.png"
            alt="Person using their phone while charger is plugged into motorcyle"
          />
        </picture>
      </template>
      <div>
        <p>
          Need help? Get answers to some of the most commonly asked questions about electric vehicles (EVs) and the
          Electrify America network on our
          <span class="wrap-last-two-words"
            ><router-link :to="{ name: 'mobile-faq-en' }" class="link medium"
              >Frequently Asked Questions</router-link
            ></span
          >
          page.
        </p>
        <p>
          For updates on the current status of the Electrify America network, scheduled maintenance, planned upgrades,
          temporary service interruptions, and more, visit the
          <a class="link medium inline" target="_blank" href="https://bit.ly/network-upgrades-and-maintenance"
            >Network Status page</a
          >.
        </p>
        <h3>Phone</h3>
        <p>1-833-632-2778<br />24 hours a day / 7 days a week</p>
        <h3>Social Media</h3>
        <a rel="noopener" target="_blank" class="button-link" href="https://www.facebook.com/ElectrifyAmerica/"
          >Facebook <Arrow class="arrow"
        /></a>
        <a rel="noopener" target="_blank" class="button-link" href="https://www.instagram.com/electrifyamerica/?hl=en"
          >Instagram <Arrow class="arrow"
        /></a>
        <a rel="noopener" target="_blank" class="button-link" href="https://x.com/electrifyam?lang=en"
          >X<Arrow class="arrow"
        /></a>
        <a
          rel="noopener"
          target="_blank"
          class="button-link"
          href="https://www.linkedin.com/company/electrifyamerica/mycompany/"
          >Linkedin <Arrow class="arrow"
        /></a>
      </div>
    </Card>
    <section class="cta-cards">
      <div class="l-three-col">
        <!-- <div class="card flex-column">
          <h2>Submissions</h2>
          <p>
            To share your suggestions for Electrify America’s investment planning process, please fill out our
            Submissions form.
          </p>
          <router-link class="button" :to="{ name: 'submissions-en' }">SUBMIT YOUR IDEA</router-link>
        </div> -->
        <div class="card flex-column">
          <h2>Marketing opportunities</h2>
          <p>Talk to us about having Electrify America sponsor your event.</p>
          <router-link class="button" :to="{ name: 'marketing-opportunities-en' }">WORK WITH US</router-link>
        </div>
        <div class="card flex-column">
          <h2>Sign up for updates</h2>
          <p>Stay up-to-date on Electrify America location openings and other news.</p>
          <router-link class="button" :to="{ name: 'sign-up-en' }">SUBSCRIBE</router-link>
        </div>
      </div>
    </section>
    <section class="form">
      <div class="container">
        <h2>Don’t see what you’re looking for?</h2>
        <p>Fill out the form and we’ll get back to you soon.</p>
        <form id="contact-form" @submit.prevent ref="form">
          <p aria-hidden="true" class="form-legend">An asterisk (<span>*</span>) indicates a required field</p>
          <label ref="firstname" for="first_name"
            >First name <span class="require-mark" aria-hidden="true">*</span>
            <input
              v-model="firstName"
              id="first_name"
              maxlength="39"
              name="first_name"
              pattern="^[a-zA-Z0-9]+(?:[a-zA-Z0-9-])+(?:[a-zA-Z0-9])$"
              type="text"
              required
            />
          </label>
          <label ref="lastname" for="last_name"
            >Last name <span class="require-mark" aria-hidden="true">*</span>
            <input
              v-model="lastName"
              id="last_name"
              maxlength="39"
              name="last_name"
              type="text"
              pattern="^[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+(?: +[A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-_]+)*$"
              title="This field only accepts letters and special characters (no trailing spaces)"
              required
            />
          </label>
          <label ref="email" for="email"
            >Email <span aria-hidden="true" class="require-mark">*</span>
            <input
              v-model="eMail"
              id="email"
              maxlength="80"
              name="email"
              type="text"
              pattern="[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])"
              title="Please enter the correct email address"
              required
            />
          </label>
          <label for="phone"
            >Phone
            <input
              v-model="phoneNumber"
              id="phone"
              maxlength="10"
              name="phone"
              type="text"
              pattern="^\d+$"
              title="This field only accepts numbers"
            />
          </label>
          <label for="topics"
            >Topics <span class="require-mark" aria-hidden="true">*</span>
            <select name="topics" title="Topics" v-model="topic" required>
              <option v-for="(item, index) in topicList" :value="item" :key="index">{{ item }}</option>
            </select>
          </label>
          <label for="comments"
            >How can we help?
            <textarea maxlength="200" id="comments" name="comments" v-model="comments"></textarea>
          </label>
          <label class="checkbox" for="contactme"
            ><input v-model="contactMe" id="contactme" name="contactme" type="checkbox" value="true" /> Contact me with
            a response
          </label>
          <label class="checkbox" for="future-comm">
            <input v-model="futureCom" id="future-comm" name="future-comm" type="checkbox" value="true" />
            I would like to receive further information from Electrify America
          </label>
          <label class="checkbox" for="market-research">
            <input v-model="futureRes" id="market-research" name="market-research" type="checkbox" value="true" />
            I consent that Electrify America may contact me to potentially engage in market research to help improve its
            network
          </label>
          <input
            ref="submitButton"
            class="button button--submit"
            name="Sign up"
            type="submit"
            value="SUBMIT"
            @click="validate"
          />
          <p v-show="serverError !== ''" class="server-error">
            Error:
            <br />
            {{ serverError }}
          </p>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow.svg';

import ApiService from '@/services/Api';
import Hero from '@/components/Hero/Hero.vue';
import Card from '@/components/Card/Card.vue';

export default {
  name: 'contactus',
  metaInfo: {
    title: 'Contact us with a question or problem | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Have a question or need to report a problem? Electrify America wants to hear from you. Fill out the form and we’ll get back to you soon.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/contact-us/' }],
  },
  components: {
    Arrow,
    Card,
    Hero,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      eMail: null,
      phoneNumber: null,
      comments: null,
      contactMe: null,
      futureCom: null,
      futureRes: null,
      topic: null,
      topicList: [
        'Chargers',
        'Company News',
        'Events',
        'General Information',
        'Green Cities',
        'Locations',
        'Press Releases',
        'Pricing',
        'Receipt Request',
        'Report an Issue',
        'Submissions',
        'Sustainability',
        'Website',
        'Other',
      ],
      serverError: '',
      chatAvailable: null,
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
    isTiny() {
      return this.$resize && this.$mq.below(430);
    },
  },
  created() {
    setTimeout(() => {
      if (document.querySelector('.helpButtonEnabled')) {
        this.chatAvailable = true;
      } else {
        this.chatAvailable = false;
      }
    }, 2000);
  },
  methods: {
    resetLableColor() {
      this.$refs.email.style.color = '#505875';
      this.$refs.firstname.style.color = '#505875';
      this.$refs.lastname.style.color = '#505875';
    },
    async validate() {
      if (!this.firstName || !this.lastName || !this.eMail || !this.topic) {
        this.$refs.form.scrollIntoView();
        return;
      }
      this.$refs.submitButton.disabled = true;
      this.serverError = '';
      try {
        const params = {
          site: 'ELAM',
          recaptchaToken: await this.recaptcha(),
          body: {
            SuppliedFirstName: this.firstName,
            SuppliedLastName: this.lastName,
            SuppliedEmail: this.eMail,
            SuppliedPhone: this.phoneNumber,
            Customercomments: this.comments,
            ContactMewithaResponse: this.contactMe,
            Topic: this.topic,
            OptMeinFutureComm: this.futureCom,
            OptMeinFutureResearch: this.futureRes,
          },
        };

        this.contactusPut(params);
      } catch (error) {
        console.error(error);
        this.$refs.submitButton.disabled = false;
      }
    },
    async recaptcha() {
      try {
        // Wait for recaptcha loading
        await this.$recaptchaLoaded();
        // Execute reCAPTCHA with action "login" and return the token
        return await this.$recaptcha('login');
      } catch (e) {
        throw new Error('recaptcha login failed');
      }
    },
    contactusPut(params) {
      const loader = this.$loading.show();
      this.$store.commit('setEmail', params.body.SuppliedEmail);
      ApiService.init();
      ApiService.put('/v1/salesforce/contactus', params)
        .then((res) => {
          if (res.status === 'success') {
            this.$router.push({ name: 'email-verification-en' });
          } else {
            this.serverError = res.errorMsg;
          }
          loader.hide();
          this.$refs.submitButton.disabled = false;
        })
        .catch((error) => {
          // Commenting out surfacing Salesforce errors to end users until we've made messaging more robust
          /*
          if (error.response.status === 500) {
            this.serverError = 'There was an error submitting the form, please try again';
          } else {
            this.serverError = error.response.data.errMsg;
          }
          */
          this.serverError = 'There was an error submitting the form, please try again';

          this.$refs.submitButton.disabled = false;
          loader.hide();
          console.error(error);
        });
    },
    openHelpMenu() {
      const c = document.createEvent('MouseEvents');
      c.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      const button = document.querySelector('.helpButtonEnabled');
      button.dispatchEvent(c);
    },
  },
  mounted() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>

<style scoped lang="scss">
.contact-us {
  //  Default Page Styling
  .form-legend {
    margin-bottom: 16px;
  }

  h2 {
    @media (min-width: 750px) {
      font-size: 32px;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 1;
    font-weight: $fw-medium;
  }

  input[type='checkbox'] {
    margin: 0;
  }

  // Utility Classes
  .container {
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  // Section Grouping

  .cta-cards,
  .form {
    padding: 60px 0;
    @media (min-width: 1130px) {
      padding: 100px 0;
    }
  }

  .form {
    background-color: $c-secondary-background;
  }

  // Sections

  .cta-cards {
    .card {
      border-radius: 8px;
      padding: 40px 32px;
      border: 1px solid #c4c4c4;
      flex: 1;
      margin-right: 0px;
      margin-bottom: 24px;
      @media (min-width: 1000px) {
        margin-right: 40px;
      }

      h3 {
        margin-bottom: 16px;
      }
      h2 {
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 32px;
      }

      .button {
        margin-top: auto;
        width: initial;
      }
    }

    .card:last-child {
      margin-right: 0;
    }
  }

  .form {
    @media (min-width: 1130px) {
      padding-left: 250px;
    }
    h2 {
      margin-bottom: 8px;
    }

    #contact-form {
      margin-top: 48px;
    }

    input,
    textarea,
    select {
      background-color: transparent;
    }

    input[type='text'],
    textarea,
    select {
      margin-bottom: 40px;
    }

    input[type='checkbox'] {
      margin-right: 22px;
    }

    .button {
      margin-top: 32px;
    }

    textarea {
      padding: 10px 12px;
    }
  }
  .contact-card {
    @media (min-width: 1130px) {
      padding-top: 120px;
    }
    img {
      width: 100% !important;
      margin-top: 48px;
      @media (min-width: 800px) {
        margin-top: 0;
        max-width: 494px;
        margin-left: 20px;
        width: 50%;
        align-self: center;
      }
    }
    h3 {
      margin-top: 32px;
      margin-bottom: 16px;
    }
    h2 {
      margin-bottom: 16px;
    }

    .button-link {
      display: block;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 0;
    }
    .inactive {
      pointer-events: none;
      cursor: not-allowed;
      color: $c-border-darker !important;
      .arrow {
        path {
          fill: $c-border-darker;
        }
      }
    }
  }
}
</style>
